<template>
  <div class="container" v-title :data-title="$t('i18n.roleAuthorization')">
    <div id="outer-title">{{ $t("i18n.roleAuthorization") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.roleAuthorization')"
      v-model="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-tree
        :props="defaultProps"
        :data="permissionData"
        show-checkbox
        ref="treeRef"
        node-key="id"
        :check-strictly="true"
        :default-checked-keys="checkNode"
        @check="nodeClick"
      ></el-tree>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">
            {{ $t("i18n.cancel") }}
          </el-button>
          <el-button type="primary" @click="handlePermission">
            {{ $t("i18n.confirm") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance, ref } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "RolePermissionView",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const treeRef = ref(null);
    const state = reactive({
      formInline: {
        page: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("添加角色"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("编辑角色"), // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "roleName",
          align: "left",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "inUse",
          label: "status",
          align: "center",
          search: true,
          row: false,
          type: "select",
          props: { label: "locale", value: "id" },
          data: [
            { id: "0", locale: "forbid" },
            { id: "1", locale: "inUse" },
          ],
          formatter: (row) => {
            if (row.inUse == 1) {
              return `<span style="color: #67c23a">${t("i18n.inUse")}</span>`;
            } else {
              return `<span>${t("i18n.forbid")}</span>`;
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 240,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "enable",
            icon: "el-icon-delete",
            show: proxy.$defined.btnPermission("禁用角色"),
            plain: false,
            disabled: false,
            type: "inner",
            formatter: (row) => {
              return row.inUse == 0 ? t("i18n.enable") : t("i18n.forbid");
            },
            method: (index, row) => {
              handleDelete(row);
            },
          },
          {
            id: "2",
            label: "roleAuthorization",
            icon: "el-icon-setting",
            show: proxy.$defined.btnPermission("编辑角色"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row, formInline) => {
              state.formInline = formInline;
              state.dialogFormVisible = true;
              state.editId = row.id;
              state.checkNode = proxy.$defined.getArrayByParam(
                row.permissionList,
                "id"
              );
            },
          },
        ],
      },
      total: 0,
      loading: true,
      dialogFormVisible: false,
      defaultProps: {
        children: "children",
        label: "locale",
      },
      permissionData: [],
      checkNode: [],
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.rolePage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      val.permissionList = [];
      val.id
        ? await proxy.$api.system.editRole(val)
        : await proxy.$api.system.addRole(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const handleDelete = (row) => {
      var tag =
        row.inUse == 1
          ? "是否继续禁用该用户？"
          : "该用户已经被禁用，是否解禁？";
      const tips = row.inUse == 1 ? "forbid" : "inUse";

      let callback = () => {
        proxy.$api.system.delRole({ id: row.id }).then(() => {
          init(state.formInline);
          proxy.$defined.tip(t("i18n." + tips) + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(callback, tag, t("i18n.edit"));
    };

    const getPermission = async () => {
      let { data } = await proxy.$api.system.getAllPermission();
      data.map((item) => {
        item.locale = t("i18n." + item.locale);
        item.disabled = !item.currentRole;
      });
      translateDataToTree(data);
      state.loginAuth = data.filter((item) => item.name == "登录")[0].id;
    };
    getPermission();

    const translateDataToTree = (array) => {
      let parent = array.filter(
        (item) => item.category === "1级菜单" || item.category === "1级功能"
      ); //第一层数据
      let children = array.filter((item) => item.category !== "1级菜单"); //有父节点的数据
      state.permissionData = translator(parent, children);
    };

    const translator = (parents, children) => {
      // 处理权限数据
      parents.forEach((parent) => {
        children.forEach((item, index) => {
          //找到子层的父层
          if (item.parentId === parent.id) {
            let temp = JSON.parse(JSON.stringify(children));
            temp.splice(index, 1);
            parent.children
              ? parent.children.push(item)
              : (parent.children = [item]);
            translator([item], temp);
          }
        });
      });
      return parents;
    };

    const handlePermission = async () => {
      let checkNodes =
        treeRef.value.getCheckedNodes() === null
          ? []
          : treeRef.value.getCheckedNodes();
      if (
        checkNodes.filter((item) => item.id == state.loginAuth).length === 0
      ) {
        checkNodes.push({ id: state.loginAuth });
      }
      let obj = {
        permissionList: checkNodes,
        id: state.editId,
      };
      await proxy.$api.system.editRole(obj);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      state.dialogFormVisible = false;
      // 初始化 刷新
      init(state.formInline);
    };

    const nodeClick = (currentObj, treeStatus) => {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      let selected = treeStatus.checkedKeys.indexOf(currentObj.id); // -1未选中,>=0为选中
      // 选中
      if (selected !== -1) {
        // 子节点只要被选中父节点就被选中(需要选中父节点时候调用此方法)
        selectedParent(currentObj);
        // 统一处理子节点为相同的勾选状态
        uniteChildSame(currentObj, true);
      } else {
        // 未选中 处理子节点全部未选中
        if (currentObj.children) {
          uniteChildSame(currentObj, false);
        }
      }
    };

    const uniteChildSame = (treeList, isSelected) => {
      // 统一处理子节点为相同的勾选状态
      treeRef.value.setChecked(treeList.id, isSelected);
      if (treeList.children) {
        for (let i = 0; i < treeList.children.length; i++) {
          uniteChildSame(treeList.children[i], isSelected);
        }
      }
    };

    const selectedParent = (currentObj) => {
      // 统一处理父节点为选中
      let currentNode = treeRef.value.getNode(currentObj);
      if (currentNode.parent.key !== undefined) {
        treeRef.value.setChecked(currentNode.parent, true);
        selectedParent(currentNode.parent);
      }
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      treeRef,
      handlePermission,
      nodeClick,
    };
  },
};
</script>
